.App {
  width: 100%;
  min-height: 100vh;
  overflow: "hidden";
  background: linear-gradient(white, whitesmoke) no-repeat;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(45deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.rotating-element {
  animation: rotate 10s infinite ease-in-out;
}
